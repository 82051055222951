import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { INaracka } from '../../models/prodavnica/naracka';
import { BarkodPostModel } from '../../models/prodavnica/barkod-post';
import { IStavkaZaNaracka } from '../../models/prodavnica/naracka';

@Injectable()
export class NarackaService {

    constructor(public httpClient: HttpClient) {}

    getNaracki(): Observable<INaracka[]> { return this.httpClient.get<INaracka[]>(`/OnlineApi/Naracka/GetNaracki`); }

    syncNaracki() { return this.httpClient.get(`/OnlineApi/Naracka/SyncNaracki`); }

    syncArtikli() { return this.httpClient.get(`/OnlineApi/Naracka/SyncArtikli`); }

    getStavkiZaNaracka(id: number): Observable<IStavkaZaNaracka[]> {
        return this.httpClient.get<IStavkaZaNaracka[]>(`/OnlineApi/Naracka/GetStavkiZaNaracka/${id}`);
    }

    status(status: number, id: number) { return this.httpClient.get(`/OnlineApi/Naracka/Status/${status}/${id}`); }

    zamena(id: number) { return this.httpClient.get(`/OnlineApi/Naracka/Zamena/${id}`); }

    getStavkiZaZamena(id: number): Observable<IStavkaZaNaracka[]> {
        return this.httpClient.get<IStavkaZaNaracka[]>(`/OnlineApi/Naracka/GetStavkiZaZamena/${id}`);
    }

    postZamena(id: number, idOld: number): Observable<IStavkaZaNaracka> {
        return this.httpClient.get<IStavkaZaNaracka>(`/OnlineApi/Naracka/PostZamena/${id}/${idOld}`);
    }

    vidNaIsporaka(id: number, vid: number) {
        return this.httpClient.get(`/OnlineApi/Naracka/VidNaIsporaka/${id}/${vid}`);
    }

    postStavka(model: BarkodPostModel): Observable<IStavkaZaNaracka> {
        return this.httpClient.post<IStavkaZaNaracka>(`/OnlineApi/Naracka/PostStavka`, model);
    }

    resetStavka(id: number) { return this.httpClient.get(`/OnlineApi/Naracka/ResetStavka/${id}`); }

}
