import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { BarkodPostModel } from '../../models/prodavnica/barkod-post';
import { IPrenosnica, IDetaliZaPrenosnica, IStavkaZaPrenosnica } from '../../models/prodavnica/prenosnica';

@Injectable()
export class PrenosnicaService {

    constructor(private http: HttpClient) {}

    getDokument(id: string): Observable<IPrenosnica> {
        return this.http.get<IPrenosnica>(`/OnlineApi/Prenosnica/GetDokument/${id}`);
    }

    postDokument(id: number): Observable<IDetaliZaPrenosnica> {
        return this.http.get<IDetaliZaPrenosnica>(`/OnlineApi/Prenosnica/PostDokument/${id}`);
    }

    postBarkod(stavka: BarkodPostModel): Observable<IStavkaZaPrenosnica> {
        return this.http.post<IStavkaZaPrenosnica>(`/OnlineApi/Prenosnica/PostBarkod`, stavka);
    }

    postBarkodBroenje(stavka: BarkodPostModel): Observable<IStavkaZaPrenosnica> {
        return this.http.post<IStavkaZaPrenosnica>(`/OnlineApi/Prenosnica/PostBarkodBroenje`, stavka);
    }

    resetBarkodBroenje(id: number): Observable<IStavkaZaPrenosnica> {
        return this.http.get<IStavkaZaPrenosnica>(`/OnlineApi/Prenosnica/ResetBarkodBroenje/${id}`);
    }

    deleteStavka(id: number) { return this.http.get(`/OnlineApi/Prenosnica/DeleteStavka/${id}`); }

    zatvoriPrenosnica(id: number) { return this.http.get(`/OnlineApi/Prenosnica/Zatvori/${id}`); }

    azurirajPrenosnica(id: number) { return this.http.get(`/OnlineApi/Prenosnica/Azuriraj/${id}`); }
}
