import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { BarkodPostModel } from '../../models/prodavnica/barkod-post';
import { INivelacija, IStavkaZaNivelacija } from '../../models/prodavnica/nivelacija';

@Injectable()
export class NivelacijaService {

    constructor(private http: HttpClient) {}

    getDokument(id: string): Observable<INivelacija> {
        return this.http.get<INivelacija>(`/OnlineApi/Nivelacija/GetDokument/${id}`);
    }

    postBarkodBroenje(stavka: BarkodPostModel): Observable<IStavkaZaNivelacija> {
        return this.http.post<IStavkaZaNivelacija>(`/OnlineApi/Nivelacija/PostBarkodBroenje`, stavka);
    }

    resetBarkodBroenje(id: number): Observable<IStavkaZaNivelacija> {
        return this.http.get<IStavkaZaNivelacija>(`/OnlineApi/Nivelacija/ResetBarkodBroenje/${id}`);
    }

    azurirajNivelacija(id: number) { return this.http.get(`/OnlineApi/Nivelacija/Azuriraj/${id}`); }

}
