import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { IKdfiKasa } from '../../models/prodavnica/kdfi';
import { ITrgovskaKnigaListaModel } from '../../models/prodavnica/trgovska-kniga';
import { IQuickViewProdavnica } from '../../models/prodavnica/quick-view';
import { QuickViewPostModel } from '../../models/prodavnica/quick-view';
import { IProverkaNaArtikl } from '../../models/prodavnica/proverka-na-artikl';
import { PregledPoPeriodPostModel } from '../../models/prodavnica/pregled-po-period';
import { IStavkaZaPregledPoPeriod } from '../../models/prodavnica/pregled-po-period';
import { ISyncLog } from '../../models/prodavnica/sync';
import { IKarticaStavka } from '../../models/prodavnica/kartica';

@Injectable()
export class IzvestaiService {

    constructor(private http: HttpClient) {}

    getKdfi(mesec: string): Observable<IKdfiKasa[]> {
        return this.http.get<IKdfiKasa[]>(`/OnlineApi/Izvestai/GetKdfi/${mesec}`);
    }

    getEt(): Observable<ITrgovskaKnigaListaModel[]> {
        return this.http.get<ITrgovskaKnigaListaModel[]>(`/OnlineApi/Izvestai/GetEt`);
    }

    getQuickView(model: QuickViewPostModel): Observable<IQuickViewProdavnica> {
        return this.http.post<IQuickViewProdavnica>(`/OnlineApi/Izvestai/GetQuickView`, model);
    }

    getProverka(barkod: string): Observable<IProverkaNaArtikl> {
        return this.http.get<IProverkaNaArtikl>(`/OnlineApi/Izvestai/GetProverka/${barkod}`);
    }

    getPregeldPoPeriod(model: PregledPoPeriodPostModel): Observable<IStavkaZaPregledPoPeriod[]> {
        return this.http.post<IStavkaZaPregledPoPeriod[]>(`/OnlineApi/Izvestai/GetPregledPoPeriod`, model);
    }

    getSyncLog(): Observable<ISyncLog[]> { return this.http.get<ISyncLog[]>(`/OnlineApi/Izvestai/GetSyncLog`); }

    changeSync(id: number): Observable<ISyncLog> {
        return this.http.get<ISyncLog>(`/OnlineApi/Izvestai/ChangeSync/${id}`);
    }

    kartica(sifra: string): Observable<IKarticaStavka[]> {
        return this.http.get<IKarticaStavka[]>(`/OnlineApi/Izvestai/Kartica/${sifra}`);
    }
}
