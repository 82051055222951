import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpEvent } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ISlikaResponse } from '../../models/prodavnica/artikl';
import { IVcitanArtikl } from '../../models/prodavnica/artikl';
import { VcitanArtiklPostModel } from '../../models/prodavnica/artikl';
import { LokacijaPostModel } from '../../models/prodavnica/artikl';
import { ILokacija } from '../../models/prodavnica/artikl';

@Injectable()
export class ArtiklService {

    constructor(public httpClient: HttpClient) {}

    editArtiklCheckLista(model: VcitanArtiklPostModel): Observable<IVcitanArtikl> {
        return this.httpClient.post<IVcitanArtikl>(`/OnlineApi/Artikl/EditArtiklCheckLista`, model);
    }

    postSlika(id: number, files: FormData): Observable<HttpEvent<void>> {
        const req = new HttpRequest('POST', `/OnlineApi/Artikl/PostSlika/${id}`, files, { reportProgress: true, });
        return this.httpClient.request<void>(req);
    }

    getSlika(id: number): Observable<ISlikaResponse> {
        return this.httpClient.get<ISlikaResponse>(`/OnlineApi/Artikl/GetSlika/${id}`);
    }

    postLokacija(model: LokacijaPostModel): Observable<LokacijaPostModel> {
        return this.httpClient.post<LokacijaPostModel>(`/OnlineApi/Artikl/Lokacija`, model);
    }

    getLokacii(): Observable<ILokacija[]> { return this.httpClient.get<ILokacija[]>(`/OnlineApi/Artikl/GetLokacii`); }

}
