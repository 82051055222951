import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { IEncodedResult } from '../../models/shared/encoded-result';

@Injectable()
export class ZebraService {

    constructor(public httpClient: HttpClient) {}

    etiketa(id: number, cena: number, kopii: number, staraCena = 0): Observable<IEncodedResult> {
        return this.httpClient.get<IEncodedResult>(`/OnlineApi/Zebra/Etiketa/${id}/${cena}/${kopii}/${staraCena}`);
    }

    generateLink(encodedResult: IEncodedResult) { return `zebraPrint://${encodedResult.data}/`; }

    print(encodedResult: IEncodedResult) {
        const data = this.generateLink(encodedResult);
        const fileTag = document.createElement('a');
        fileTag.href = data;
        fileTag.click();
    }

}
