import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ISostojba } from '../../models/prodavnica/sostojba';

@Injectable()
export class SostojbaService {

    sostojba: ISostojba;

    constructor(public http: HttpClient) {}

    getSostojba(): Observable<ISostojba> { return this.http.get<ISostojba>(`/OnlineApi/Sostojba/GetSostojba`); }

}
